import React, { FC,useState } from "react";
import avatar2 from "../../assets/images/homepage/person_two-min.png";
import avatar from "../../assets/images/homepage/person_one-min.png";
import agiflyLogo from "../../assets/logo/logo-agifly-footer-blanc.png";
import agiflyLogoBlue from "../../assets/logo/logo-agifly-footer-color.png";

import axios from "axios";


interface FooterInterface {}

const Footer: FC<FooterInterface> = () => {
  const [ error, setError ] = useState(false);
  const [ errorType, setErrorType ] = useState("");
  const [ success, setSuccess ] = useState(false)

  const handleSubmit = (event:any)  => {  
    event.preventDefault();
    setError(false)
    setSuccess(false)
    let naam = event.target[0].value,
        voornaam =event.target[1].value,
        email = event.target[2].value,
        phone = event.target[3].value,
        msg = event.target[4].value;

    axios.post("https://backend.production.frs.gae.agiprod.agifly.cloud/contact", {
          naam,
          voornaam,
          email,
          phone,
          msg,
        })
        .then((response) => {
          setSuccess(true)
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].param)
          if(error.response.data.errors[0].param === "phone"){
            setErrorType("Telefoon klopt niet")
          }
          if(error.response.data.errors[0].param === "email"){
            setErrorType("E-mailadress klopt niet")
          }
          if(error.response.data.errors[0].param === "naam" || error.response.data.errors[0].param === "voornaam"){
            setErrorType("Naam of Voornaam klopt niet")
          }
          setError(true)
          setSuccess(false)
        })

  }

  return (
    <div>
      <div className="lg:px-0 px-5 py-12 bg-[#F4F4EF]">
        <h3 className="text-[#0e5b49] font-bold text-4xl pb-4 text-center">
          Neem contact op met ons
        </h3>
        <h3 className="text-[#C4A469] font-bold text-4xl pb-4 text-center">
          voor meer info of een afspraak.
        </h3>
      </div>
      <div className="bg-[#052317]">
        <div className="container w-full lg:w-[90%] mx-auto pt-24 pb-12 lg:px-0 px-5">
          <div className="flex lg:flex-row flex-col items-start justify-between">
            <div className="adress-section w-full lg:w-[32%]">
              <p className="text-white opacity-70 pb-6">
                RESIDENTIE FRANS ROBBRECHTSSTRAAT 56
              </p>
              <div className="flex items-center">
                <div className="w-[118px]">
                  <span className="text-white opacity-30">ADRES:</span>
                </div>
                <div>
                  <p className="text-white opacity-70">
                    FRANS ROBBRECHTSSTRAAT 56 1780 WEMMEL
                  </p>
                </div>
              </div>
              <div className="flex items-center pt-5">
                <div className="w-[118px]">
                  <span className="text-white opacity-30">TELEFOON:</span>
                </div>
                <div>
                  <p className="text-white opacity-70">09 245 03 33</p>
                </div>
              </div>
              <div className="flex items-center pt-8">
                <div className="w-[118px]">
                  <span className="text-white opacity-30">EMAIL:</span>
                </div>
                <div>
                  <p className="text-[#cca160] opacity-70">
                    {" "}
                    info@gaelensbouwprojecten.be
                  </p>
                </div>
              </div>
            </div>
            <div className="user-section pt-12 lg:pt-0">
              <p className="text-white opacity-70 pb-6">CONTACTEER ONS</p>
              <div className="flex items-center pb-6">
                <img
                  className="rounded-full bg-white"
                  height={70}
                  width={70}
                  src={avatar}
                  alt="user"
                />
                <div className="pl-6">
                  <p className="text-white opacity-30">CHARLOTTE WELLEMAN</p>
                  <p className="text-white opacity-70">+32 498 93 79 36</p>
                </div>
              </div>
              <div className="w-full mx-auto ml-12 bg-white py-[0.1px] opacity-30" />
              <div className="flex items-center pb-6 pt-8">
                <img
                  className="rounded-full bg-white"
                  height={70}
                  width={70}
                  src={avatar2}
                  alt="user"
                />
                <div className="pl-6">
                  <p className="text-white opacity-30">PIETER-JAN WINDEY</p>
                  <p className="text-white opacity-70">+32 472 80 20 65</p>
                </div>
              </div>
            </div>
            <div className="form-section pt-12 lg:pt-0 w-full lg:w-fit">
              <p className="text-white opacity-70 pb-6">
              We horen graag van u, neem contact op
              </p>
              <form className="w-full" onSubmit={handleSubmit}>
                <div className="flex flex-col lg:flex-row justify-between">
                  <div className="flex flex-col lg:mr-4">
                    <label htmlFor="fname" className="text-white opacity-70">
                      Naam
                    </label>
                    <input
                      name="fname"
                      type="text"
                      required
                      className="bg-[#2a4139] p-2 text-white "
                    ></input>
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="lname" className="text-white opacity-70">
                      Voornaam
                    </label>
                    <input
                      name="lname"
                      type="text"
                      required
                      className="bg-[#2a4139] p-2 text-white "
                    ></input>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-between pt-2">
                  <div className="flex flex-col lg:mr-4">
                    <label htmlFor="email" className="text-white opacity-70">
                      E-mailadress
                    </label>
                    <input
                      name="email"
                      type="email"
                      required
                      className="bg-[#2a4139] p-2 text-white "
                    ></input>
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="phone" className="text-white opacity-70">
                      Telefoon
                    </label>
                    <input
                      name="phone"
                      type="tel"
                      required
                      className="bg-[#2a4139] p-2 text-white "
                    ></input>
                  </div>
                </div>
                <div className="flex flex-col pt-2">
                  <label htmlFor="message" className="text-white opacity-70">
                    Uw bericht
                  </label>
                  <textarea
                    name="message"
                    className="bg-[#2a4139] resize-none px-2 py-3 h-28 text-white "
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="py-2 w-full bg-[#295A49] mt-3 text-white opacity-70"
                >
                  VERSTUREN
                </button>
              </form>


              {success ? (              
              <div className="flex items-center bg-green-500 text-white text-sm font-bold px-4 py-3 mt-2" role="alert">
                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
                <p>Bericht verzonden. Dank je wel</p>
              </div>):null }  
              {error ? (              
              <div className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3 mt-2" role="alert">
                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
                {errorType ? (<p>Foutmelding : {errorType}</p>):(<p>Foutmelding : Gelieve later opnieuw te proberen.</p>)}
              </div>):null }              

            </div>
          </div>
          <div className="mt-12">
            <div className="w-full mx-auto bg-white py-[0.1px] opacity-30" />
            <div className=" flex justify-between pt-6">
              <p className="text-[#cca160] opacity-70">
                {" "}
                @GAELENSBOUWPROJECTEN
              </p>
              {/* <img src={} alt="logo" /> */}
              <div
                  className="relative overflow-hidden bg-no-repeat bg-cover w-32 group"
                >
                  <a href="https://agifly.be" target="_blank">
                  <img src={agiflyLogo} className="opacity-100  group-hover:opacity-0 transition duration-300 ease-in-out" alt="Agifly's Logo" />
                  <img src={agiflyLogoBlue} className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0  group-hover:opacity-100 transition duration-300 ease-in-out" alt="Agifly's Logo in blue" />
                  </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
