import Routes from "./routes";
import { GlobalProvider } from "./context/globalContext/globalContext";
import "./styles/index.css";
// photo view css
import 'react-multi-carousel/lib/styles.css';
import 'react-photo-view/dist/react-photo-view.css';

const App = () => {
  return (
    <GlobalProvider>
      <Routes />
    </GlobalProvider>
  );
};

export default App;
