import React, { FC } from "react";
import DurrzaamFirst from "../../../assets/images/homepage/durrzaamFirst.png";
import DurrzaamSecond from "../../../assets/images/homepage/duurzaamSecond.png";
interface DuurzaamInterface {}

const HomePageDuurzzam: FC<DuurzaamInterface> = () => {
  return (
    <section id="duurzaam" className="bg-[#F4F4EF] py-20 mt-24 px-5 lg:px-0">
      <div className="container mx-auto ">
        <div className="flex flex-col items-center pb-5 lg:pb-24">
          <h3 className="text-[#295A49] font-bold text-[46px]">
            Duurzaam Wonen
          </h3>
          <p className="text-[#BFA571] text-[22px] opacity-70 pt-2">
            {" "}
            Duurzaam Staat Centraal
          </p>
        </div>
        <div className="flex lg:flex-row flex-col justify-center lg:justify-between">
          {/* flex lg:pl-[150px] w-full lg:w-[33%] */}
          <div className="flex  w-full lg:w-6/12">
            <div>
              <img
                src={DurrzaamFirst}
                alt="building"
                className="shadow-xl lg:h-fit lg:-fit"
              />
            </div>
            <div className="pl-4">
              <img
                className="mt-24 shadow-xl lg:h-fit lg:w-fit"
                src={DurrzaamSecond}
                alt="interior"
              />
            </div>
          </div>
          {/* lg:pr-12 mt-12 lg:mt-24 lg:pl-0 pl-4 */}
          <div className="w-full lg:w-6/12 p-4">
            <div className=" text-[#080808]">
              <div className="pb-8">
                1. Doordachte inplanting met maximaal gebruik van zonlicht
                <div className="pt-3 pl-3">
                  <div className="pt-1 flex">
                    <div className="w-3 h-3 mr-2 mt-[6px] border-2 border-[#BFA571] rounded-full"></div>
                    <p className="w-11/12">Zongerichte terrassen en/of tuinen</p>
                  </div>
                  <div className="pt-1 flex">
                    <div className="w-3 h-3 mr-2 mt-[6px] border-2 border-[#BFA571] rounded-full"></div>
                    <p className="w-11/12">Conform de EPB-reglementeringen</p>
                  </div>
                </div>
              </div>
              <div className="pb-4">
                2. Energiezuinig bouwen
                <div className="pt-3 pl-3">
                  <div className="pt-1 flex">
                    <div className="w-3 h-3 mr-2 mt-[6px] border-2 border-[#BFA571] rounded-full"></div>
                    <p className="w-11/12">Optimaal energiezuinig gebouw door de extra aandacht voor
                    isolatie van de buitenwanden en het dak.</p>
                  </div>
                  <div className="pt-1 flex">
                    <div className="w-3 h-3 mr-2 mt-[6px] border-2 border-[#BFA571] rounded-full"></div>
                    <p className="w-11/12">Er wordt gebruik gemaakt van een individuele geothermische
                    warmtepomp die zijn energie opwekt door geothermische
                    boringen om het appartement te verwarmen en passief te
                    koelen.</p>
                  </div>
                  <div className="pt-1 flex">
                    <div className="w-3 h-3 mr-2 mt-[6px] border-2 border-[#BFA571] rounded-full"></div>
                    <p className="w-11/12">De warmtepomp beschikt over een Smart Price Adaption
                    regeling.</p>
                  </div>
                  <div className="pt-1 flex">
                    <div className="w-3 h-3 mr-2 mt-[6px] border-2 border-[#BFA571] rounded-full"></div>
                    <p className="w-11/12">Bovendien wordt gebruik gemaakt van fotovoltaïsche
                    zonnepanelen voor alle appartementen en de
                    gemeenschappelijke delen en dit volgens de EPB-studie. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageDuurzzam;
