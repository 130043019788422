import React, { FC } from "react";
import Carousel from "react-multi-carousel";
import { ScreenSize } from "../../../utils/windowDimension";
import { PhotoProvider, PhotoView } from "react-photo-view";
// import HeroImage from "../../../assets/images/homepage/hero.png";
import p1 from "../../../assets/images/gallery/opt/01.jpg";
import p2 from "../../../assets/images/gallery/opt/02.jpg";
import p3 from "../../../assets/images/gallery/opt/03.jpg";
import p4 from "../../../assets/images/gallery/opt/04.jpg";
import p5 from "../../../assets/images/gallery/opt/05.jpg";
import p6 from "../../../assets/images/gallery/opt/06.jpg";
// import LiggingHero from "../../../assets/images/homepage/ligging.png";

interface GalleryInterface {}

const HomePageGallery: FC<GalleryInterface> = () => {
  const screenSize = ScreenSize();
  // gallery data
  const galleryData = [
    {
      id: 1,
      imageUrl: p1,
    },
    {
      id: 2,
      imageUrl: p2,
    },
    {
      id: 3,
      imageUrl: p3,
    },
    {
      id: 4,
      imageUrl: p4,
    },
    {
      id: 5,
      imageUrl: p5,
    },
    {
      id: 6,
      imageUrl: p6,
    },
  ];
  // carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section id="galerij" className="pt-24 gallery">
        <div className="relative">
          <PhotoProvider>
            <div>
              <Carousel
                responsive={responsive}
                arrows={false}
                autoPlay
                infinite
                showDots={true}
              >
                {galleryData?.map((item, index) => (
                  <PhotoView key={index} src={item.imageUrl}>
                    <div
                      style={{
                        background: `URL(${item.imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition:"center center",
                        height: screenSize?.width > 1023 ? "600px" : "300px",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div
                        className="fixed inset-0 w-[50%] md:w-[35%] lg:w-[35%] z-20"
                        style={{ backgroundColor: "rgba(79,102,67,0.4)" }}
                      />
                      <button
                        type="button"
                        className="fixed bottom-[10%] left-[13%] lg:left-[18%] border border-2 border-white px-4 lg:px-12 py-2 lg:py-3 text-white z-50"
                      >
                        Ontdek
                      </button>
                    </div>
                  </PhotoView>
                ))}
              </Carousel>
            </div>
          </PhotoProvider>
          <div>
            <p className="absolute top-[10%] text-[34px] lg:text-[46px] left-[8%] lg:left-[15%] px-4 lg:px-12 py-2 lg:py-3 text-white z-30">
              Galerij
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageGallery;
