import React, { FC } from "react";
import LiggingHero from "../../../assets/images/homepage/ligging.png";
import { Map, Marker } from "pigeon-maps"
import logo from "../../../assets/logo/logo-white.svg";

interface LiggingInterface {}

const HomePageHero: FC<LiggingInterface> = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [zoom, setZoom] = React.useState(18)
  const [center, setCenter] = React.useState([50.911927, 4.306469])

  return (
    <section id="ligging" className="min-h-[700px] lg:px-0 px-5">
      <div className="container w-full lg:w-9/12 mx-auto flex lg:flex-row flex-col-reverse justify-between pt-24">
        <div className="w-full lg:w-[48%] pt-6 lg:pt-0">
          <h3 className="text-[#0e5b49] font-bold text-[46px]">
            Ligging en Bereikbaarheid
          </h3>
          <p className="text-[#BFA571] text-[22px] pt-2">
            Wonen nabij het centrum
          </p>
          <p className="py-3 text-[18px]">
            Het project is gelegen nabij het centrum van de gemeente Wemmel met
            zijn vele handelszaken, supermarkten, restaurants en sportclubs.
            Verschillende scholen bevinden zich op wandel- of fietsafstand
            van het gebouw.
          </p>
          <p className="py-3 text-[18px]">
            Op wandelafstand van het project is een busstation (Lijn 241 en Lijn 543) van waaruit men de bus kan nemen richting Brussel of Merchtem.
          </p>
          <div className="flex justify-center lg:flex-col">
            <p className="py-3 text-[18px]">
              Met de wagen is er een goede aantsluiting met de Ring rond Brussel
              en met de autosnelwegen naar Antwerpen en Gent/Brugge/Oostende.
            </p>
          </div>
          <div className="flex justify-center lg:flex-col">
            <button className="py-3 px-6 mx-auto bg-[#0e5b49] text-[22px] mt-5 text-white" onClick={() => setShowModal(true)}>
              VIA GOOGLE ONTDEKKEN
            </button>
          </div>
        </div>
        <div className="z-10 flex justify-center lg:flex-col max-w-full lg:max-w-[50%] lg:absolute lg:right-0">
          <img width={640} src={LiggingHero} alt="building" />
        </div>
      </div>
      {showModal ? 
      (
        <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-9/12">
            {/*content*/}
            <div className="relative flex flex-col w-full outline-none focus:outline-none">
  
              {/*body*/}
              <div className="relative">
              <button
                  className=" absolute z-50 bg-transparent text-black  right-3 top-0 m-0 p-0 text-6xl font-semibold outline-none focus:outline-none 	"
                  onClick={() => setShowModal(false)}
                >
                    ×
                </button>

              <Map height={window.innerHeight *0.75} defaultCenter={[50.91240856125075, 4.306316184128377]} defaultZoom={18} zoom={zoom} minZoom={18} maxZoom={18}>

                <Marker width={100*zoom/18} anchor={[50.911927, 4.306469]} className="duration-2000	ease-in-out	">
                <img
                
                style={{
                  
                }}
                height={100*zoom/18}
                width={100*zoom/18}
                src={logo}
                alt="logo"
              />
                </Marker>
              </Map>
              </div>            
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => setShowModal(false)}></div>
      </>
      ):null
      }

    </section>
  );
};

export default HomePageHero;
