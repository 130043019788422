export const record = [
  // Parking Record
  {
    name: "Parking 1 (Box)",
    price: "35.000",
    type: "parking",
  },
  {
    name: "Parking 2 (Box)",
    price: "35.000",
    type: "parking",
  },
  {
    name: "Parking 3 (Box)",
    price: "35.000",
    type: "parking",
  },
  {
    name: "Parking 4",
    price: "26.000",
    type: "parking",
  },
  {
    name: "Parking 5",
    price: "26.000",
    type: "parking",
  },
  {
    name: "Parking 6",
    price: "26.000",
    type: "parking",
  },
  {
    name: "Parking 7",
    price: "26.000",
    type: "parking",
  },
  {
    name: "Parking 8",
    price: "26.000",
    type: "parking",
  },
  {
    name: "Parking 9",
    price: "26.000",
    type: "parking",
  },
  {
    name: "Parking 10",
    price: "26.000",
    type: "parking",
  },
  {
    name: "Parking 11 (MV)",
    price: "30.000",
    type: "parking",
  },
  {
    name: "Parking 12 & 13 (Box 2P)",
    price: "56.000",
    type: "parking",
  },
  // Appartementen
  {
    name: "Appartementen A1.A",
    // price: "€ 517.300",
    price: "Verkocht",
    location: "2 Slaapkamers • Niveau 1 • Oppervlakte 125 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-1.A.pdf"),
    img: require("../images/homepage/maps/1A.png"),
  },
  {
    name: "Appartementen Duplex A1.B",
    // price: "€ 745.200",
    price: "Verkocht",

    location: "4 Slaapkamers • Niveau 1 & 2  • Oppervlakte 170,80 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-1.B.pdf"),
    img: require("../images/homepage/maps/1B.png"),
  },
  {
    name: "Appartementen A1.C",
    // price: "€ 397.200",
    price: "Verkocht",
    location: "2 Slaapkamers • Niveau 1 • Oppervlakte 89,60 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-1.C.pdf"),
    img: require("../images/homepage/maps/1C.png"),
  },
  {
    name: "Appartementen Duplex A1.D",
    // price: "€ 648.500",
    price: "Verkocht",
    location: "3+ Slaapkamers • Niveau 1 & 2 • Oppervlakte 152,80 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-1.D.pdf"),
    img: require("../images/homepage/maps/1D.png"),
  },
  {
    name: "Appartementen A1.E",
    // price: "€ 399.800",
    price: "Verkocht",
    location: "2 Slaapkamers • Niveau 1 • Oppervlakte 91,60 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-1.E.pdf"),
    img: require("../images/homepage/maps/1E.png"),
  },
  {
    name: "Appartementen A0.A",
    price: "€ 484.600",
    location: "2 Slaapkamers • Niveau 0 • Oppervlakte 107,80 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop.0.A.pdf"),
    img: require("../images/homepage/maps/0A.png"),
  },
  {
    name: "Appartementen A0.B",
    // price: "€ 498.600",
    price: "Verkocht",
    location: "2 Slaapkamers • Niveau 0 • Oppervlakte 111,60 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-0.B.pdf"),
    img: require("../images/homepage/maps/0B.png"),
  },
  {
    name: "Appartementen A0.C",
    price: "€ 488.400",
    location: "2 Slaapkamers • Niveau 0 • Oppervlakte 108,20 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-0.C.pdf"),
    img: require("../images/homepage/maps/0C.png"),
  },
  {
    name: "Appartementen A0.D",
    //price: "€ 492.600",
    price: "Verkocht",
    location: "2 Slaapkamers • Niveau 0 • Oppervlakte 108,20 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-0.D.pdf"),
    img: require("../images/homepage/maps/0D.png"),
  },
  {
    name: "Appartementen A0.E",
    price: "Verkocht",
    location: "3 Slaapkamers • Niveau 0 • Oppervlakte 114,30 m2",
    type: "appartementen",
    pdf: require("../images/homepage/maps/verkoop-0.E.pdf"),
    img: require("../images/homepage/maps/0E.png"),
  },
  // Berginegn
  {
    name: "Kelder 1",
    op:   "15,23",
    price: "8.500",
    type: "bergingen",
  },
  {
    name: "Kelder 2",
    op:   "13,96",
    price: "7.800",
    type: "bergingen",
  },
  {
    name: "Kelder 3",
    op:   "12,14",
    price: "6.800",
    type: "bergingen",
  },
  {
    name: "Kelder 4",
    op:   "8,94",
    price: "5.500",
    type: "bergingen",
  },
  {
    name: "Kelder 5",
    op:"15,23",
    price: "6.000",
    type: "bergingen",
  },
  {
    name: "Kelder 6",
    op:   "10,31",
    price: "6.000",
    type: "bergingen",
  },
  {
    name: "Kelder 7",
    op:    "10,31",
    price: "6.000",
    type: "bergingen",
  },
  {
    name: "Kelder 8",
    op:"15,23",
    price: "5.500",
    type: "bergingen",
  },
  {
    name: "Kelder 9",
    op:"15,23",
    price: "6.500",
    type: "bergingen",
  },
  {
    name: "Kelder 10",
    op:"15,23",
    price: "5.000",
    type: "bergingen",
  },
];

export const photos = [
  {
    src: "https://picsum.photos/1200/500",
    width: 800,
    height: 600,
  },
  {
    src: "https://picsum.photos/1200/501",
    width: 700,
    height: 400,
  },
  {
    src: "https://picsum.photos/1200/502",
    width: 400,
    height: 900,
  },
  {
    src: "https://picsum.photos/1200/504",
    width: 500,
    height: 600,
  },
  {
    src: "https://picsum.photos/1200/505",
    width: 800,
    height: 600,
  },
  {
    src: "https://picsum.photos/1200/506",
    width: 1600,
    height: 900,
  },
  {
    src: "https://picsum.photos/1200/507",
    width: 800,
    height: 600,
  },
  {
    src: "https://picsum.photos/1200/509",
    width: 800,
    height: 600,
  },
  {
    src: "https://picsum.photos/1200/512",
    width: 700,
    height: 400,
  },
  {
    src: "https://picsum.photos/1200/511",
    width: 400,
    height: 900,
  },
  {
    src: "https://picsum.photos/1200/514",
    width: 500,
    height: 600,
  },
  {
    src: "https://picsum.photos/1200/519",
    width: 800,
    height: 800,
  },
  {
    src: "https://picsum.photos/1200/520",
    width: 1600,
    height: 400,
  },
  {
    src: "https://picsum.photos/1200/522",
    width: 800,
    height: 1200,
  },
];
