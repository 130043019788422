import { Link } from "react-scroll";
import React, { FC, useState } from "react";
import { Pivot as Hamburger } from "hamburger-react";
import logo from "../../assets/logo/logo-white.svg";
import ContactModal from "../modals/contactModal";

interface HeaderInterface {}

const Header: FC<HeaderInterface> = () => {
  const [mobileNav, setMobilenav] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // mobile view toggler
  const menuToggle = () => {
    setMobilenav(!mobileNav);
  };

  return (
    <>
      <header className="bg-white z-100 fixed left-0 right-0 top-0 z-40 py-3 lg:py-4 shadow-none lg:shadow-lg px-7 lg:px-0 border-none lg:border-b-2">
        <nav className=" flex h-16	 w-full lg:w-9/12 mx-auto relative hidden lg:block ">
          <ul className="flex items-center justify-between m-0 text-base h-full self-center	mx-20">
            <li>
              <Link
                activeClass="active"
                smooth
                spy={true}
                to="project"
                className="text-[#0e5b49] cursor-pointer"
              >
                PROJECT
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                smooth
                spy={true}
                to="ligging"
                className="cursor-pointer text-[#0e5b49]"
              >
                LIGGING
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                smooth
                spy={true}
                to="duurzaam"
                className="cursor-pointer text-[#0e5b49]"
              >
                DUURZAAMHEID
              </Link>
            </li>
            <li className=" w-2/12	max-w-[140px]"
>
              <img
                className="absolute left-0 right-[73px] top-0 mx-auto w-2/12	max-w-[140px] -mt-2"
                src={logo}
                alt="logo"
              />
            </li>
            <li>
              <Link
                activeClass="active"
                smooth
                spy
                to="appartementen"
                className="cursor-pointer text-[#0e5b49]"
              >
                APPARTEMENTEN
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                smooth
                spy
                to="galerij"
                className="cursor-pointer text-[#0e5b49]"
              >
                GALERIJ
              </Link>
            </li>
            <button
              className="text-white bg-[#0e5b49] py-3 px-4 flex items-center justify-center"
              onClick={() => {
                setShowModal(true);
              }}
            >
              NEEM CONTACT OP
            </button>
          </ul>
        </nav>
        <div className="flex justify-between block lg:hidden">
          <img
            className="rounded-full bg-white"
            height={60}
            width={60}
            src={logo}
            alt="logo"
          />
          <button
            onClick={() => {
              menuToggle();
            }}
            type="button"
          >
            <Hamburger />
          </button>
        </div>
      </header>
      {/* Mobile Menu */}
      {mobileNav && (
        <div className="mobile-menu block lg:hidden">
          <div className="mobile-nav z-100 flex flex-col justify-around w-full fixed px-6 bg-primary-gray h-screen pt-4 overflow-y-scroll sm:pt-28 sm:pb-4 lg:pt-4 lg:pb-0 bg-white">
            <div>
              <nav>
                <ul>
                  <li className="text-4xl font-semibold mb-7 text-black">
                    <Link
                      activeClass="active"
                      smooth
                      spy={true}
                      to="project"
                      onClick={() => {
                        menuToggle();
                      }}
                      className="text-[#0e5b49] cursor-pointer"
                    >
                      PROJECT
                    </Link>
                  </li>
                  <li className="text-4xl font-semibold mb-7 text-black">
                    {" "}
                    <Link
                      onClick={() => {
                        menuToggle();
                      }}
                      activeClass="active"
                      smooth
                      spy={true}
                      to="ligging"
                      className="cursor-pointer text-[#0e5b49]"
                    >
                      LIGGING
                    </Link>
                  </li>
                  <li className="text-4xl font-semibold mb-7 text-black">
                    <Link
                      onClick={() => {
                        menuToggle();
                      }}
                      activeClass="active"
                      smooth
                      spy={true}
                      to="duurzaam"
                      className="cursor-pointer text-[#0e5b49]"
                    >
                      DUURZAAMHEID
                    </Link>
                  </li>
                  <li className="text-4xl font-semibold mb-7 text-black">
                    {" "}
                    <Link
                      onClick={() => {
                        menuToggle();
                      }}
                      activeClass="active"
                      smooth
                      spy
                      to="appartementen"
                      className="cursor-pointer text-[#0e5b49]"
                    >
                      APPARTEMENTEN
                    </Link>
                  </li>
                  <li className="text-4xl font-semibold mb-7 text-black">
                    {" "}
                    <Link
                      onClick={() => {
                        menuToggle();
                      }}
                      activeClass="active"
                      smooth
                      spy
                      to="galerij"
                      className="cursor-pointer text-[#0e5b49]"
                    >
                      GALERIJ
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <button
                      className="text-white bg-[#0e5b49] py-3 px-4 flex items-center justify-center"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      NEEM CONTACT OP
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      )}
      {showModal && <ContactModal setShowModal={setShowModal} />}
    </>
  );
};
export default Header;
