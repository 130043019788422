import React, { FC } from "react";
import Header from "./header";
import Footer from "./footer";
interface LayoutInterface {
  children: JSX.Element;
}

const Layout: FC<LayoutInterface> = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
export default Layout;
