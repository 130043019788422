import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
// pages
import HomePage from "../containers/home";
// layout
import Layout from "../shared/layout";

interface RoutesInterface {}

/**
 * routes page to define all pages routes here
 */
const PagesRoutes: FC<RoutesInterface> = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Layout>
  );
};
export default PagesRoutes;
