import React, { FC } from "react";
import HeroSection from "../../components/homepage/heroSection";
import Ligging from "../../components/homepage/ligging";
import Duurrzaam from "../../components/homepage/duurzaam";
import Appartementen from "../../components/homepage/appartement";
import Gallery from "../../components/homepage/gallery";

interface HomePageInterface {}

const HeaderFilter: FC<HomePageInterface> = () => {
  return (
    <div id="containerElement">
      <HeroSection />
      <Ligging/>
      <Duurrzaam />
      <Appartementen />
      <Gallery />
    </div>
  );
};

export default HeaderFilter;
