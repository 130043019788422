import React, { FC,useState } from "react";
import Logo from "../../../assets/logo/logo.svg";
import CloseIcon from "../../../assets/icons/closeWhite.png";
import axios from "axios";

interface FormModalInterface {
  setShowModal?: any;
}

const ContactModal: FC<FormModalInterface> = ({ setShowModal }) => {
  const [ error, setError ] = useState(false);
  const [ errorType, setErrorType ] = useState("");
  const [ success, setSuccess ] = useState(false)

  const handleSubmit = (event:any)  => {  
    event.preventDefault();
    setError(false)
    setSuccess(false)
    let naam = event.target[0].value,
        voornaam =event.target[1].value,
        email = event.target[2].value,
        phone = event.target[3].value,
        msg = event.target[4].value;

    axios.post("https://backend.production.frs.gae.agiprod.agifly.cloud/contact", {
          naam,
          voornaam,
          email,
          phone,
          msg,
        })
        .then((response) => {
          setSuccess(true)
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].param)
          if(error.response.data.errors[0].param === "phone"){
            setErrorType("Telefoon klopt niet")
          }
          if(error.response.data.errors[0].param === "email"){
            setErrorType("E-mailadress klopt niet")
          }
          if(error.response.data.errors[0].param === "naam" || error.response.data.errors[0].param === "voornaam"){
            setErrorType("Naam of Voornaam klopt niet")
          }
          setError(true)
          setSuccess(false)
        })

  }

  return (
    <div
      className="h-screen z-50 fixed inset-0"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <div className="container mx-auto">
        <div className="w-[90%] md:w[60%] lg:w-[40%] mx-auto bg-[#0e5b49] fixed inset-0 h-fit mt-16">
          <div className=" w-full lg:w-[90%] mx-auto pt-4 pb-12 lg:px-0 px-5">
            <div className="w-full">
              <div className="form-section pt-12 lg:pt-0 w-full">
                <div className="w-full flex justify-end">
                  <img
                    src={CloseIcon}
                    className="h-[10px] cursor-pointer absolute mr-[-14px] mt-[-5px] z-50"
                    onClick={() => setShowModal(false)}
                    alt=""
                  />
                </div>
                <div className="flex items-center justify-between pb-2">
                  <p className="text-white text-sm lg:text-3xl pb-6 w-[70%]">
                    We horen graag van u,<br /> neem contact op
                  </p>
                  <img
                    className="h-[50px] lg:h-[120px] w-fit ml-12"
                    src={Logo}
                    alt="logo"
                  />
                </div>
                <form className="w-full" onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:flex-row justify-between w-full">
                    <div className="flex flex-col lg:mr-4 w-full">
                      <label
                        htmlFor="fname"
                        className="text-white opacity-70 text-[18px] pb-1"
                      >
                        Naam
                      </label>
                      <input
                        name="fname"
                        type="text"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                    <div className="flex flex-col w-full">
                      <label
                        htmlFor="lname"
                        className="text-white opacity-70 text-[18px] pb-1"
                      >
                        Voornaam
                      </label>
                      <input
                        name="lname"
                        type="text"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row justify-between pt-2 w-full">
                    <div className="flex flex-col lg:mr-4 w-full">
                      <label
                        htmlFor="email"
                        className="text-white opacity-70 text-[18px]  pb-1"
                      >
                        E-mailadress
                      </label>
                      <input
                        name="email"
                        type="email"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                    <div className="flex flex-col w-full">
                      <label
                        htmlFor="phone"
                        className="text-white opacity-70 text-[18px] pb-1"
                      >
                        Telefoon
                      </label>
                      <input
                        name="phone"
                        type="tel"
                        required
                        className="bg-[#377260] text-white outline-none p-2"
                      ></input>
                    </div>
                  </div>
                  <div className="flex flex-col pt-2">
                    <label
                      htmlFor="message"
                      className="text-white opacity-70 text-[18px] pb-1"
                    >
                      Uw bericht
                    </label>
                    <textarea
                      name="message"
                      className="bg-[#377260] resize-none  text-white  outline-none px-2 py-3 h-24"
                    ></textarea>
                  </div>
                  <div className="flex items-center pb-5">
                    <button
                      type="submit"
                      className="py-2 w-[30%] text-[18px] mx-auto bg-[#0e5b49] border border-2 border-white mt-3 text-white opacity-70"
                    >
                      VERSTUREN
                    </button>
                  </div>
                </form>

              {success ? (              
              <div className="flex items-center bg-green-500 text-white text-sm font-bold px-4 py-3 mt-2" role="alert">
                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
                <p>Bericht verzonden. Dank je wel</p>
              </div>):null }  
              {error ? (              
              <div className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3 mt-2" role="alert">
                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
                {errorType ? (<p>Foutmelding : {errorType}</p>):(<p>Foutmelding : Gelieve later opnieuw te proberen.</p>)}
              </div>):null }              

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactModal;
